/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DevolutionsInvoicesNotesTotalsDto
 */
export interface DevolutionsInvoicesNotesTotalsDto {
    /**
     * 
     * @type {number}
     * @memberof DevolutionsInvoicesNotesTotalsDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof DevolutionsInvoicesNotesTotalsDto
     */
    totalWithoutNotes: number;
}

/**
 * Check if a given object implements the DevolutionsInvoicesNotesTotalsDto interface.
 */
export function instanceOfDevolutionsInvoicesNotesTotalsDto(value: object): value is DevolutionsInvoicesNotesTotalsDto {
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('totalWithoutNotes' in value) || value['totalWithoutNotes'] === undefined) return false;
    return true;
}

export function DevolutionsInvoicesNotesTotalsDtoFromJSON(json: any): DevolutionsInvoicesNotesTotalsDto {
    return DevolutionsInvoicesNotesTotalsDtoFromJSONTyped(json, false);
}

export function DevolutionsInvoicesNotesTotalsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DevolutionsInvoicesNotesTotalsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'total': json['total'],
        'totalWithoutNotes': json['totalWithoutNotes'],
    };
}

export function DevolutionsInvoicesNotesTotalsDtoToJSON(value?: DevolutionsInvoicesNotesTotalsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'total': value['total'],
        'totalWithoutNotes': value['totalWithoutNotes'],
    };
}

