import Summary from "./components/summary";
import { PageContainer } from "@ant-design/pro-components";
import InvoiceRecords from "./components/invoice-records";
import { Affix, Button, Col, Row } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useState } from "react";

const HomePage = () => {
  const [showSummary, setShowSummary] = useState(false);

  return (
    <PageContainer
      title={
        <Button
          icon={showSummary ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          onClick={() => setShowSummary(!showSummary)}
          type="default"
        />
      }
    >
      <Row gutter={[16, 16]}>
        {showSummary && (
          <Col span={6}>
            <Affix>
              <Summary />
            </Affix>
          </Col>
        )}
        <Col span={showSummary ? 18 : 24}>
          <InvoiceRecords />
        </Col>
      </Row>
    </PageContainer>
  );
};

export default HomePage;
