/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BgProcessDto,
  PaginationResponseDto,
} from '../models/index';
import {
    BgProcessDtoFromJSON,
    BgProcessDtoToJSON,
    PaginationResponseDtoFromJSON,
    PaginationResponseDtoToJSON,
} from '../models/index';

export interface QuotasDataControllerExportAllRequest {
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    sourceId?: string;
    ownerId?: string;
    dataRowTransformed?: string;
    dataDocMultiplierNumber?: string;
    dataMultiplierName?: string;
    dataRegion?: string;
    dataDistrict?: string;
    dataOwnerTrait?: string;
    dataTechnology?: string;
    dataBrand?: string;
    dataProduct?: string;
    dataBatchName?: string;
    dataPms?: string;
    dataType?: string;
    dataAvailable?: string;
    dataConsumed?: string;
    dataBlocked?: string;
    dataTotal?: string;
}

export interface QuotasDataControllerFindAllRequest {
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    sourceId?: string;
    ownerId?: string;
    dataRowTransformed?: string;
    dataDocMultiplierNumber?: string;
    dataMultiplierName?: string;
    dataRegion?: string;
    dataDistrict?: string;
    dataOwnerTrait?: string;
    dataTechnology?: string;
    dataBrand?: string;
    dataProduct?: string;
    dataBatchName?: string;
    dataPms?: string;
    dataType?: string;
    dataAvailable?: string;
    dataConsumed?: string;
    dataBlocked?: string;
    dataTotal?: string;
}

/**
 * 
 */
export class QuotasApi extends runtime.BaseAPI {

    /**
     */
    async quotasDataControllerExportAllRaw(requestParameters: QuotasDataControllerExportAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BgProcessDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['sourceId'] != null) {
            queryParameters['sourceId'] = requestParameters['sourceId'];
        }

        if (requestParameters['ownerId'] != null) {
            queryParameters['ownerId'] = requestParameters['ownerId'];
        }

        if (requestParameters['dataRowTransformed'] != null) {
            queryParameters['dataRowTransformed'] = requestParameters['dataRowTransformed'];
        }

        if (requestParameters['dataDocMultiplierNumber'] != null) {
            queryParameters['dataDocMultiplierNumber'] = requestParameters['dataDocMultiplierNumber'];
        }

        if (requestParameters['dataMultiplierName'] != null) {
            queryParameters['dataMultiplierName'] = requestParameters['dataMultiplierName'];
        }

        if (requestParameters['dataRegion'] != null) {
            queryParameters['dataRegion'] = requestParameters['dataRegion'];
        }

        if (requestParameters['dataDistrict'] != null) {
            queryParameters['dataDistrict'] = requestParameters['dataDistrict'];
        }

        if (requestParameters['dataOwnerTrait'] != null) {
            queryParameters['dataOwnerTrait'] = requestParameters['dataOwnerTrait'];
        }

        if (requestParameters['dataTechnology'] != null) {
            queryParameters['dataTechnology'] = requestParameters['dataTechnology'];
        }

        if (requestParameters['dataBrand'] != null) {
            queryParameters['dataBrand'] = requestParameters['dataBrand'];
        }

        if (requestParameters['dataProduct'] != null) {
            queryParameters['dataProduct'] = requestParameters['dataProduct'];
        }

        if (requestParameters['dataBatchName'] != null) {
            queryParameters['dataBatchName'] = requestParameters['dataBatchName'];
        }

        if (requestParameters['dataPms'] != null) {
            queryParameters['dataPms'] = requestParameters['dataPms'];
        }

        if (requestParameters['dataType'] != null) {
            queryParameters['dataType'] = requestParameters['dataType'];
        }

        if (requestParameters['dataAvailable'] != null) {
            queryParameters['dataAvailable'] = requestParameters['dataAvailable'];
        }

        if (requestParameters['dataConsumed'] != null) {
            queryParameters['dataConsumed'] = requestParameters['dataConsumed'];
        }

        if (requestParameters['dataBlocked'] != null) {
            queryParameters['dataBlocked'] = requestParameters['dataBlocked'];
        }

        if (requestParameters['dataTotal'] != null) {
            queryParameters['dataTotal'] = requestParameters['dataTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/quotas/list/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BgProcessDtoFromJSON(jsonValue));
    }

    /**
     */
    async quotasDataControllerExportAll(requestParameters: QuotasDataControllerExportAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BgProcessDto> {
        const response = await this.quotasDataControllerExportAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async quotasDataControllerFindAllRaw(requestParameters: QuotasDataControllerFindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['sourceId'] != null) {
            queryParameters['sourceId'] = requestParameters['sourceId'];
        }

        if (requestParameters['ownerId'] != null) {
            queryParameters['ownerId'] = requestParameters['ownerId'];
        }

        if (requestParameters['dataRowTransformed'] != null) {
            queryParameters['dataRowTransformed'] = requestParameters['dataRowTransformed'];
        }

        if (requestParameters['dataDocMultiplierNumber'] != null) {
            queryParameters['dataDocMultiplierNumber'] = requestParameters['dataDocMultiplierNumber'];
        }

        if (requestParameters['dataMultiplierName'] != null) {
            queryParameters['dataMultiplierName'] = requestParameters['dataMultiplierName'];
        }

        if (requestParameters['dataRegion'] != null) {
            queryParameters['dataRegion'] = requestParameters['dataRegion'];
        }

        if (requestParameters['dataDistrict'] != null) {
            queryParameters['dataDistrict'] = requestParameters['dataDistrict'];
        }

        if (requestParameters['dataOwnerTrait'] != null) {
            queryParameters['dataOwnerTrait'] = requestParameters['dataOwnerTrait'];
        }

        if (requestParameters['dataTechnology'] != null) {
            queryParameters['dataTechnology'] = requestParameters['dataTechnology'];
        }

        if (requestParameters['dataBrand'] != null) {
            queryParameters['dataBrand'] = requestParameters['dataBrand'];
        }

        if (requestParameters['dataProduct'] != null) {
            queryParameters['dataProduct'] = requestParameters['dataProduct'];
        }

        if (requestParameters['dataBatchName'] != null) {
            queryParameters['dataBatchName'] = requestParameters['dataBatchName'];
        }

        if (requestParameters['dataPms'] != null) {
            queryParameters['dataPms'] = requestParameters['dataPms'];
        }

        if (requestParameters['dataType'] != null) {
            queryParameters['dataType'] = requestParameters['dataType'];
        }

        if (requestParameters['dataAvailable'] != null) {
            queryParameters['dataAvailable'] = requestParameters['dataAvailable'];
        }

        if (requestParameters['dataConsumed'] != null) {
            queryParameters['dataConsumed'] = requestParameters['dataConsumed'];
        }

        if (requestParameters['dataBlocked'] != null) {
            queryParameters['dataBlocked'] = requestParameters['dataBlocked'];
        }

        if (requestParameters['dataTotal'] != null) {
            queryParameters['dataTotal'] = requestParameters['dataTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/quotas/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async quotasDataControllerFindAll(requestParameters: QuotasDataControllerFindAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponseDto> {
        const response = await this.quotasDataControllerFindAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
