// src/pages/HomePage.js
import React, { createRef, useState } from "react";
import {
  ActionType,
  PageContainer,
  ProColumns,
  ProTable,
} from "@ant-design/pro-components";
import { useAPI } from "../../client-api/utils/api";
import {
  QuotasApi,
  QuotasDataControllerFindAllRequest,
} from "../../client-api";
import BgProcessPopover from "../../components/bg-process-popover";
import { cleanObj } from "../../components/utils";

export const royaltiesNotesDataColumns: ProColumns[] = [
  {
    title: "id",
    dataIndex: "id",
    hidden: true,
    hideInSearch: true,
  },
  {
    dataIndex: "dataDocMultiplierNumber",
    title: "Doc.Multiplicador",
    width: 120,
  },
  {
    dataIndex: "dataMultiplierName",
    title: "Nome Multiplicador",
    width: 120,
  },
  { dataIndex: "dataRegion", title: "Região", width: 120 },
  { dataIndex: "dataDistrict", title: "Distrito", width: 120 },
  { dataIndex: "dataOwnerTrait", title: "Trait owner", width: 120 },
  { dataIndex: "dataTechnology", title: "Tecnologia", width: 120 },
  { dataIndex: "dataBrand", title: "Marca", width: 120 },
  { dataIndex: "dataProduct", title: "Produto", width: 120 },
  { dataIndex: "dataBatchName", title: "Lote", width: 120 },
  { dataIndex: "dataPms", title: "PMS", width: 120 },
  { dataIndex: "dataType", title: "Tipo", width: 120 },
  { dataIndex: "dataAvailable", title: "Disponível", width: 120 },
  { dataIndex: "dataConsumed", title: "Consumido", width: 120 },
  { dataIndex: "dataBlocked", title: "Bloqueado", width: 120 },
  { dataIndex: "dataTotal", title: "Total", width: 120 },
];

const QuatasList: React.FC = () => {
  const api = useAPI(QuotasApi);
  const [loading, setLoading] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<any>();
  const actionRef: any = createRef<ActionType>();

  return (
    <PageContainer title={false}>
      <ProTable
        // antd site header height
        scroll={{ x: "100vw", y: "100vh" }}
        sticky={{ offsetHeader: 56 }}
        loading={loading}
        columns={royaltiesNotesDataColumns}
        actionRef={actionRef}
        beforeSearchSubmit={(params) => {
          const p = cleanObj(params);
          return p;
        }}
        request={(params, sorter, filter) => {
          setLoading(true);
          const query = {
            page: params?.current,
            pageSize: params?.pageSize,
            ...params,
          } as QuotasDataControllerFindAllRequest;
          setCurrentFilters(params);
          return api
            .quotasDataControllerFindAll(query)
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            })
            .finally(() => setLoading(false));
        }}
        rowKey="id"
        pagination={{ defaultPageSize: 20 }}
        search={{ filterType: "query", layout: "vertical" }}
        dateFormatter="string"
        toolBarRender={() => [
          <BgProcessPopover
            action="export"
            type={["quotas"]}
            doExport={() =>
              api.quotasDataControllerExportAll({
                ...currentFilters,
              })
            }
          />,
        ]}
      />
    </PageContainer>
  );
};

export default QuatasList;
