/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BgProcessDto,
  PaginationResponseDto,
} from '../models/index';
import {
    BgProcessDtoFromJSON,
    BgProcessDtoToJSON,
    PaginationResponseDtoFromJSON,
    PaginationResponseDtoToJSON,
} from '../models/index';

export interface RoyaltiesNotesDataControllerExportAllRequest {
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    key?: string;
    sourceId?: string;
    ownerId?: string;
    harvest?: string;
    saleType?: string;
    invoice?: string;
    invoiceDate?: string;
    creationDate?: string;
    buyerName?: string;
    buyerIdentifierNumber?: string;
    plantingState?: string;
    plantingCity?: string;
    product?: string;
    batch?: string;
    brand?: string;
    technology?: string;
    quantity?: string;
    netUnitPrice?: string;
    calculatedNetValue?: string;
    dueDate?: string;
    billingType?: string;
    saleStatus?: string;
    sellerName?: string;
    sellerIdentifierNumber?: string;
    sellerState?: string;
    sellerCity?: string;
    creditVolume?: string;
    keyIntegration?: string;
}

export interface RoyaltiesNotesDataControllerFindAllRequest {
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    key?: string;
    sourceId?: string;
    ownerId?: string;
    harvest?: string;
    saleType?: string;
    invoice?: string;
    invoiceDate?: string;
    creationDate?: string;
    buyerName?: string;
    buyerIdentifierNumber?: string;
    plantingState?: string;
    plantingCity?: string;
    product?: string;
    batch?: string;
    brand?: string;
    technology?: string;
    quantity?: string;
    netUnitPrice?: string;
    calculatedNetValue?: string;
    dueDate?: string;
    billingType?: string;
    saleStatus?: string;
    sellerName?: string;
    sellerIdentifierNumber?: string;
    sellerState?: string;
    sellerCity?: string;
    creditVolume?: string;
    keyIntegration?: string;
}

/**
 * 
 */
export class RoyaltiesNotesDataApi extends runtime.BaseAPI {

    /**
     */
    async royaltiesNotesDataControllerExportAllRaw(requestParameters: RoyaltiesNotesDataControllerExportAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BgProcessDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['key'] != null) {
            queryParameters['key'] = requestParameters['key'];
        }

        if (requestParameters['sourceId'] != null) {
            queryParameters['sourceId'] = requestParameters['sourceId'];
        }

        if (requestParameters['ownerId'] != null) {
            queryParameters['ownerId'] = requestParameters['ownerId'];
        }

        if (requestParameters['harvest'] != null) {
            queryParameters['harvest'] = requestParameters['harvest'];
        }

        if (requestParameters['saleType'] != null) {
            queryParameters['saleType'] = requestParameters['saleType'];
        }

        if (requestParameters['invoice'] != null) {
            queryParameters['invoice'] = requestParameters['invoice'];
        }

        if (requestParameters['invoiceDate'] != null) {
            queryParameters['invoiceDate'] = requestParameters['invoiceDate'];
        }

        if (requestParameters['creationDate'] != null) {
            queryParameters['creationDate'] = requestParameters['creationDate'];
        }

        if (requestParameters['buyerName'] != null) {
            queryParameters['buyerName'] = requestParameters['buyerName'];
        }

        if (requestParameters['buyerIdentifierNumber'] != null) {
            queryParameters['buyerIdentifierNumber'] = requestParameters['buyerIdentifierNumber'];
        }

        if (requestParameters['plantingState'] != null) {
            queryParameters['plantingState'] = requestParameters['plantingState'];
        }

        if (requestParameters['plantingCity'] != null) {
            queryParameters['plantingCity'] = requestParameters['plantingCity'];
        }

        if (requestParameters['product'] != null) {
            queryParameters['product'] = requestParameters['product'];
        }

        if (requestParameters['batch'] != null) {
            queryParameters['batch'] = requestParameters['batch'];
        }

        if (requestParameters['brand'] != null) {
            queryParameters['brand'] = requestParameters['brand'];
        }

        if (requestParameters['technology'] != null) {
            queryParameters['technology'] = requestParameters['technology'];
        }

        if (requestParameters['quantity'] != null) {
            queryParameters['quantity'] = requestParameters['quantity'];
        }

        if (requestParameters['netUnitPrice'] != null) {
            queryParameters['netUnitPrice'] = requestParameters['netUnitPrice'];
        }

        if (requestParameters['calculatedNetValue'] != null) {
            queryParameters['calculatedNetValue'] = requestParameters['calculatedNetValue'];
        }

        if (requestParameters['dueDate'] != null) {
            queryParameters['dueDate'] = requestParameters['dueDate'];
        }

        if (requestParameters['billingType'] != null) {
            queryParameters['billingType'] = requestParameters['billingType'];
        }

        if (requestParameters['saleStatus'] != null) {
            queryParameters['saleStatus'] = requestParameters['saleStatus'];
        }

        if (requestParameters['sellerName'] != null) {
            queryParameters['sellerName'] = requestParameters['sellerName'];
        }

        if (requestParameters['sellerIdentifierNumber'] != null) {
            queryParameters['sellerIdentifierNumber'] = requestParameters['sellerIdentifierNumber'];
        }

        if (requestParameters['sellerState'] != null) {
            queryParameters['sellerState'] = requestParameters['sellerState'];
        }

        if (requestParameters['sellerCity'] != null) {
            queryParameters['sellerCity'] = requestParameters['sellerCity'];
        }

        if (requestParameters['creditVolume'] != null) {
            queryParameters['creditVolume'] = requestParameters['creditVolume'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/royalties/notes/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BgProcessDtoFromJSON(jsonValue));
    }

    /**
     */
    async royaltiesNotesDataControllerExportAll(requestParameters: RoyaltiesNotesDataControllerExportAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BgProcessDto> {
        const response = await this.royaltiesNotesDataControllerExportAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async royaltiesNotesDataControllerFindAllRaw(requestParameters: RoyaltiesNotesDataControllerFindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['key'] != null) {
            queryParameters['key'] = requestParameters['key'];
        }

        if (requestParameters['sourceId'] != null) {
            queryParameters['sourceId'] = requestParameters['sourceId'];
        }

        if (requestParameters['ownerId'] != null) {
            queryParameters['ownerId'] = requestParameters['ownerId'];
        }

        if (requestParameters['harvest'] != null) {
            queryParameters['harvest'] = requestParameters['harvest'];
        }

        if (requestParameters['saleType'] != null) {
            queryParameters['saleType'] = requestParameters['saleType'];
        }

        if (requestParameters['invoice'] != null) {
            queryParameters['invoice'] = requestParameters['invoice'];
        }

        if (requestParameters['invoiceDate'] != null) {
            queryParameters['invoiceDate'] = requestParameters['invoiceDate'];
        }

        if (requestParameters['creationDate'] != null) {
            queryParameters['creationDate'] = requestParameters['creationDate'];
        }

        if (requestParameters['buyerName'] != null) {
            queryParameters['buyerName'] = requestParameters['buyerName'];
        }

        if (requestParameters['buyerIdentifierNumber'] != null) {
            queryParameters['buyerIdentifierNumber'] = requestParameters['buyerIdentifierNumber'];
        }

        if (requestParameters['plantingState'] != null) {
            queryParameters['plantingState'] = requestParameters['plantingState'];
        }

        if (requestParameters['plantingCity'] != null) {
            queryParameters['plantingCity'] = requestParameters['plantingCity'];
        }

        if (requestParameters['product'] != null) {
            queryParameters['product'] = requestParameters['product'];
        }

        if (requestParameters['batch'] != null) {
            queryParameters['batch'] = requestParameters['batch'];
        }

        if (requestParameters['brand'] != null) {
            queryParameters['brand'] = requestParameters['brand'];
        }

        if (requestParameters['technology'] != null) {
            queryParameters['technology'] = requestParameters['technology'];
        }

        if (requestParameters['quantity'] != null) {
            queryParameters['quantity'] = requestParameters['quantity'];
        }

        if (requestParameters['netUnitPrice'] != null) {
            queryParameters['netUnitPrice'] = requestParameters['netUnitPrice'];
        }

        if (requestParameters['calculatedNetValue'] != null) {
            queryParameters['calculatedNetValue'] = requestParameters['calculatedNetValue'];
        }

        if (requestParameters['dueDate'] != null) {
            queryParameters['dueDate'] = requestParameters['dueDate'];
        }

        if (requestParameters['billingType'] != null) {
            queryParameters['billingType'] = requestParameters['billingType'];
        }

        if (requestParameters['saleStatus'] != null) {
            queryParameters['saleStatus'] = requestParameters['saleStatus'];
        }

        if (requestParameters['sellerName'] != null) {
            queryParameters['sellerName'] = requestParameters['sellerName'];
        }

        if (requestParameters['sellerIdentifierNumber'] != null) {
            queryParameters['sellerIdentifierNumber'] = requestParameters['sellerIdentifierNumber'];
        }

        if (requestParameters['sellerState'] != null) {
            queryParameters['sellerState'] = requestParameters['sellerState'];
        }

        if (requestParameters['sellerCity'] != null) {
            queryParameters['sellerCity'] = requestParameters['sellerCity'];
        }

        if (requestParameters['creditVolume'] != null) {
            queryParameters['creditVolume'] = requestParameters['creditVolume'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/royalties/notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async royaltiesNotesDataControllerFindAll(requestParameters: RoyaltiesNotesDataControllerFindAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponseDto> {
        const response = await this.royaltiesNotesDataControllerFindAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
