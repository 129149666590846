import { ExportOutlined, LinkOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useInterval, useAsyncFn } from "react-use";

import { Button, Popover, Progress, Space, Table, Tag, Tooltip } from "antd";
import { useAPI } from "../../client-api/utils/api";
import { BgProcessApi } from "../../client-api";
import dayjs from "dayjs";

interface BgProcessPopoverProps {
  type?: string[];
  action?: "export" | "import";
  doExport?: () => Promise<any>;
}

const BgProcessPopover: React.FC<BgProcessPopoverProps> = ({
  action,
  type,
  doExport,
}) => {
  const api = useAPI(BgProcessApi);
  const [open, setOpen] = useState<boolean>(false);
  const [loadingDoExport, setLoadingDoExport] = useState<boolean>(false);

  const loadData = () => {
    return api.bgProcessControllerFindAll({
      page: 1,
      pageSize: 6,
      action,
      type,
    });
  };

  const [state, doFetch] = useAsyncFn(async () => loadData());
  useInterval(doFetch, open ? 7000 : null);

  const columns = [
    {
      title: "Link",
      dataIndex: "data",
      key: "url",
      render: (_: any, record: any) => {
        if (record.status !== "finished") {
          return "--";
        }
        const limitTime = dayjs(record.createdAt).add(23, "hour");
        const disabled = dayjs(Date()).isAfter(limitTime);
        return (
          <Button
            type="link"
            target="_blank"
            href={record?.data?.output?.fileUrl}
            disabled={disabled}
            icon={<LinkOutlined />}
          >
            Baixar
          </Button>
        );
      },
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (value: string, record: any) => {
        if (value === "finished") return <Tag color="success">Finalizado</Tag>;
        if (value === "crashed") return <Tag color="error">Falhou</Tag>;
        if (value === "running")
          return (
            <Tag color="processing">
              <Progress
                type="circle"
                percent={record.progress || 0}
                size={12}
              />{" "}
              Processando
            </Tag>
          );
        return <Tag color="default">Aguardando</Tag>;
      },
    },
    {
      title: "Data",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => {
        return (
          <Space direction="vertical" size={0}>
            <>{dayjs(value).format("DD-MM-YYYY hh:mm")}</>
            <>
              {dayjs
                .duration(dayjs(value).utc().diff(dayjs().utc()))
                .locale("pt-br")
                .humanize()}
            </>
          </Space>
        );
      },
    },
  ];

  const content = (
    <Space direction="vertical">
      <Button
        icon={<ExportOutlined />}
        type="primary"
        block
        loading={loadingDoExport}
        disabled={doExport === undefined}
        onClick={() => {
          setLoadingDoExport(true);
          doExport?.().finally(() =>
            doFetch().finally(() => setLoadingDoExport(false))
          );
        }}
      >
        Exportar Tabela
      </Button>
      <></>
      <Table
        loading={state.loading && (state.value?.list || []).length === 0}
        dataSource={state.value?.list}
        columns={columns}
        title={() => "Últimas Exportações"}
        pagination={false}
        footer={() => (
          <Button type="link" disabled>
            {`ver todos os ${state.value?.total} registros`}
          </Button>
        )}
      />
    </Space>
  );
  return (
    <Popover
      content={content}
      title={false}
      trigger="click"
      placement="bottomRight"
      destroyTooltipOnHide={true}
      onOpenChange={(open: boolean) => {
        setOpen(open);
        if (open === true) doFetch();
      }}
    >
      <Tooltip title="Exportar">
        <Button icon={<ExportOutlined />} type="text" />
      </Tooltip>
    </Popover>
  );
};

export default BgProcessPopover;
