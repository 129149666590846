/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDevolutionsInvoicesNotesDataDto
 */
export interface UpdateDevolutionsInvoicesNotesDataDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDevolutionsInvoicesNotesDataDto
     */
    dataMultiplierIdentifierNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDevolutionsInvoicesNotesDataDto
     */
    dataNfe?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDevolutionsInvoicesNotesDataDto
     */
    dataIdentifierNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDevolutionsInvoicesNotesDataDto
     */
    dataDevolutionNfe?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDevolutionsInvoicesNotesDataDto
     */
    dataCustomerName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDevolutionsInvoicesNotesDataDto
     */
    dataBatch?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDevolutionsInvoicesNotesDataDto
     */
    dataWeightKq?: string;
}

/**
 * Check if a given object implements the UpdateDevolutionsInvoicesNotesDataDto interface.
 */
export function instanceOfUpdateDevolutionsInvoicesNotesDataDto(value: object): value is UpdateDevolutionsInvoicesNotesDataDto {
    return true;
}

export function UpdateDevolutionsInvoicesNotesDataDtoFromJSON(json: any): UpdateDevolutionsInvoicesNotesDataDto {
    return UpdateDevolutionsInvoicesNotesDataDtoFromJSONTyped(json, false);
}

export function UpdateDevolutionsInvoicesNotesDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDevolutionsInvoicesNotesDataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dataMultiplierIdentifierNumber': json['dataMultiplierIdentifierNumber'] == null ? undefined : json['dataMultiplierIdentifierNumber'],
        'dataNfe': json['dataNfe'] == null ? undefined : json['dataNfe'],
        'dataIdentifierNumber': json['dataIdentifierNumber'] == null ? undefined : json['dataIdentifierNumber'],
        'dataDevolutionNfe': json['dataDevolutionNfe'] == null ? undefined : json['dataDevolutionNfe'],
        'dataCustomerName': json['dataCustomerName'] == null ? undefined : json['dataCustomerName'],
        'dataBatch': json['dataBatch'] == null ? undefined : json['dataBatch'],
        'dataWeightKq': json['dataWeightKq'] == null ? undefined : json['dataWeightKq'],
    };
}

export function UpdateDevolutionsInvoicesNotesDataDtoToJSON(value?: UpdateDevolutionsInvoicesNotesDataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dataMultiplierIdentifierNumber': value['dataMultiplierIdentifierNumber'],
        'dataNfe': value['dataNfe'],
        'dataIdentifierNumber': value['dataIdentifierNumber'],
        'dataDevolutionNfe': value['dataDevolutionNfe'],
        'dataCustomerName': value['dataCustomerName'],
        'dataBatch': value['dataBatch'],
        'dataWeightKq': value['dataWeightKq'],
    };
}

