// src/pages/HomePage.js
import React, { createRef, useState } from "react";
import {
  ActionType,
  PageContainer,
  ProColumns,
  ProTable,
} from "@ant-design/pro-components";
import { Badge, Space, Spin, Typography } from "antd";
import { useAPI } from "../../client-api/utils/api";
import { DevolutionsInvoicesNotesDataApi } from "../../client-api";
import labels from "../client-billing/data-fields";
// import { defaultFilter } from "../../components/utils";
import BgProcessPopover from "../../components/bg-process-popover";
import { cleanObj, textToIdentificationNumber } from "../../components/utils";
import { EllipsisOutlined, LoadingOutlined } from "@ant-design/icons";
import { useAsyncRetry } from "react-use";
import DevolutionsNotesEdit from "../../components/devolutions-notes-edit/indes";

const ClientDevolutionsNotes: React.FC = () => {
  const actionRef: any = createRef<ActionType>();
  const api = useAPI(DevolutionsInvoicesNotesDataApi);
  const [activeKey, setActiveKey] = useState<string>("all");
  const [currentFilters, setCurrentFilters] = useState<any>();
  const [loading, setLoading] = useState(false);

  const totals = useAsyncRetry(
    async () =>
      await api.devolutionsInvoicesNotesDataControllerGetTotals(currentFilters),
    [currentFilters]
  );

  const renderText = (editData: string, data: string) => {
    if (data !== editData) {
      return (
        <Space direction="vertical" size={0}>
          <Typography.Text>{editData}</Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 10 }}>
            {data}
          </Typography.Text>
        </Space>
      );
    }
    return editData;
  };

  const clientBillingColumns: ProColumns[] = [
    {
      title: "id",
      dataIndex: "id",
      hidden: true,
      hideInSearch: true,
    },
    {
      title: <EllipsisOutlined />,
      fixed: "right",
      key: "options",
      width: 30,
      hideInSearch: true,
      render: (_, record) => (
        <Space.Compact block>
          <DevolutionsNotesEdit
            filters={{ id: record.id }}
            iconMode
            onConfirm={() => actionRef?.current?.reload()}
            size="small"
          />
        </Space.Compact>
      ),
    },
    {
      title: labels["editDataNfe"],
      dataIndex: "editDataNfe",
      width: 50,
      // ...defaultFilter("editDataNfe"),
      renderText: (text, record) => renderText(text, record.dataNfe),
    },
    {
      title: labels["editDataDevolutionNfe"],
      dataIndex: "editDataDevolutionNfe",
      width: 50,
      // ...defaultFilter("editDataDevolutionNfe"),
      renderText: (text, record) => renderText(text, record.dataDevolutionNfe),
    },
    {
      title: labels["editDataIdentifierNumber"],
      dataIndex: "editDataIdentifierNumber",
      width: 100,
      // ...defaultFilter("editDataIdentifierNumber"),
      renderText: (text, record) =>
        renderText(
          textToIdentificationNumber(text),
          textToIdentificationNumber(record.dataIdentifierNumber)
        ),
    },
    {
      title: labels["editDataBatch"],
      dataIndex: "editDataBatch",
      width: 100,
      // ...defaultFilter("editDataBatch"),
      renderText: (text, record) => renderText(text, record.dataBatch),
    },
    {
      title: labels["editDataCustomerName"],
      dataIndex: "editDataCustomerName",
      width: 250,
      // ...defaultFilter("editDataCustomerName"),
      renderText: (text, record) => renderText(text, record.dataCustomerName),
    },
    {
      title: labels["editDataMultiplierIdentifierNumber"],
      dataIndex: "editDataMultiplierIdentifierNumber",
      width: 90,
      // ...defaultFilter("editDataMultiplierIdentifierNumber"),
      renderText: (text, record) =>
        renderText(
          textToIdentificationNumber(text),
          textToIdentificationNumber(record.dataMultiplierIdentifierNumber)
        ),
    },
    {
      title: labels["editDataWeightKq"],
      dataIndex: "editDataWeightKq",
      width: 90,
      renderText: (text, record) => renderText(text, record.dataWeightKq),
    },
  ];

  const renderBadge = (text: string, count?: number) => {
    const info = totals.loading ? (
      <Spin indicator={<LoadingOutlined spin />} size="small" />
    ) : (
      count
    );
    const sufix = (
      <Badge
        count={info}
        showZero={true}
        overflowCount={999}
        color="orange"
        style={{
          marginBlockStart: -2,
          marginInlineStart: 4,
        }}
      />
    );

    return (
      <>
        {text}
        {sufix}
      </>
    );
  };

  return (
    <PageContainer title={false}>
      <ProTable
        // antd site header height
        scroll={{ x: "100vw", y: "100vh" }}
        sticky={{ offsetHeader: 56 }}
        loading={loading}
        columns={clientBillingColumns}
        actionRef={actionRef}
        // params={filteredInfo}
        search={{ layout: "vertical" }}
        beforeSearchSubmit={(params) => {
          const p = cleanObj(params);
          setCurrentFilters(p);
          return p;
        }}
        request={(params, sorter, filter) => {
          setLoading(true);
          const filterInvoices = { ...filter, ...params };
          delete filterInvoices.current;
          delete filterInvoices.pageSize;
          const getItems =
            activeKey !== "withoutNotes"
              ? api.devolutionsInvoicesNotesDataControllerFindAll({
                  page: params?.current,
                  pageSize: params?.pageSize,
                  ...filterInvoices,
                })
              : api.devolutionsInvoicesNotesDataControllerFindAllWithoutInvoices(
                  {
                    page: params?.current,
                    pageSize: params?.pageSize,
                    ...filterInvoices,
                  }
                );
          return getItems
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            })
            .finally(() => setLoading(false));
        }}
        rowKey="id"
        pagination={{ defaultPageSize: 100 }}
        dateFormatter="string"
        toolBarRender={() => [
          <BgProcessPopover
            action="export"
            type={["devolutionsInvoicesNotes"]}
            doExport={() =>
              api.devolutionsInvoicesNotesDataControllerExportAll({
                ...currentFilters,
              })
            }
          />,
        ]}
        toolbar={{
          menu: {
            type: "tab",
            activeKey: activeKey,
            items: [
              {
                key: "all",
                label: <span>{renderBadge("Todos", totals.value?.total)}</span>,
              },
              {
                key: "withoutNotes",
                label: (
                  <span>
                    {renderBadge(
                      "Notas ausentes",
                      totals.value?.totalWithoutNotes
                    )}
                  </span>
                ),
              },
            ],
            onChange: (key) => {
              setActiveKey(key as string);
              actionRef?.current?.reload();
            },
          },
        }}
      />
    </PageContainer>
  );
};

export default ClientDevolutionsNotes;
