/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaginationResponseDto,
} from '../models/index';
import {
    PaginationResponseDtoFromJSON,
    PaginationResponseDtoToJSON,
} from '../models/index';

export interface BgProcessControllerFindAllRequest {
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    action?: string;
    type?: Array<string>;
    status?: string;
}

/**
 * 
 */
export class BgProcessApi extends runtime.BaseAPI {

    /**
     */
    async bgProcessControllerFindAllRaw(requestParameters: BgProcessControllerFindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['action'] != null) {
            queryParameters['action'] = requestParameters['action'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/bgprocess`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async bgProcessControllerFindAll(requestParameters: BgProcessControllerFindAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponseDto> {
        const response = await this.bgProcessControllerFindAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
