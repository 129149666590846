/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoicesNotesTotalsDto
 */
export interface InvoicesNotesTotalsDto {
    /**
     * 
     * @type {number}
     * @memberof InvoicesNotesTotalsDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicesNotesTotalsDto
     */
    totalRevision: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicesNotesTotalsDto
     */
    totalWithoutQuotas: number;
}

/**
 * Check if a given object implements the InvoicesNotesTotalsDto interface.
 */
export function instanceOfInvoicesNotesTotalsDto(value: object): value is InvoicesNotesTotalsDto {
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('totalRevision' in value) || value['totalRevision'] === undefined) return false;
    if (!('totalWithoutQuotas' in value) || value['totalWithoutQuotas'] === undefined) return false;
    return true;
}

export function InvoicesNotesTotalsDtoFromJSON(json: any): InvoicesNotesTotalsDto {
    return InvoicesNotesTotalsDtoFromJSONTyped(json, false);
}

export function InvoicesNotesTotalsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoicesNotesTotalsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'total': json['total'],
        'totalRevision': json['totalRevision'],
        'totalWithoutQuotas': json['totalWithoutQuotas'],
    };
}

export function InvoicesNotesTotalsDtoToJSON(value?: InvoicesNotesTotalsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'total': value['total'],
        'totalRevision': value['totalRevision'],
        'totalWithoutQuotas': value['totalWithoutQuotas'],
    };
}

