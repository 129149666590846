import HomePage from "./pages/home";
import SettingsPage from "./pages/settings";
import ImportPage from "./pages/import";
import ClientBilling from "./pages/client-billing";
import ClientDevolutionsNotes from "./pages/devolutions-notes";
import RoyaltiesNotes from "./pages/imported-royalties-notes";
import UnprocessedRoyaltiesNotes from "./pages/unprocessed-royalties-notes";
import QuatasList from "./pages/quotas";
import UserPage from "./pages/user";
import {
  FileTextOutlined,
  HomeOutlined,
  ImportOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const menu = [
  {
    path: "/",
    name: "Início",
    component: HomePage,
    icon: <HomeOutlined />,
  },
  {
    path: "/relatorio/entrada",
    name: "Relatorio (Entrada)",
    icon: <FileTextOutlined />,
    routes: [
      {
        path: "clientbilling",
        name: "Faturamento",
        component: ClientBilling,
      },
      {
        path: "devolutionsnotes",
        name: "Notas de Devoluções",
        component: ClientDevolutionsNotes,
      },
      {
        path: "royaltiesnotes",
        name: "Relatório ITS",
        component: RoyaltiesNotes,
      },
      {
        path: "itsquotas",
        name: "Cotas ITS",
        component: QuatasList,
      },
    ],
  },
  {
    path: "/relatorio/saida",
    name: "Relatorio (Saida)",
    icon: <FileTextOutlined />,
    routes: [
      {
        path: "royaltiesnotes",
        name: "Relatório ITS ",
        component: UnprocessedRoyaltiesNotes,
      },
    ],
  },
  {
    path: "/datasource",
    name: "Fonte de dados",
    icon: <ImportOutlined />,
    routes: [
      {
        path: "file",
        name: "Arquivos xls",
        component: ImportPage,
      },
    ],
  },
  {
    path: "/setting",
    name: "Configurações",
    icon: <SettingOutlined />,
    routes: [
      {
        path: "general",
        name: "Geral",
        component: SettingsPage,
      },
      {
        path: "users",
        name: "Usuários",
        component: UserPage,
      },
    ],
  },
];

export default menu;
