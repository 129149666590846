import { Card } from "antd";
import { useAsyncRetry } from "react-use";
import { Treemap } from "@ant-design/plots";

import { useAPI } from "../../../client-api/utils/api";
import { ReportsApi, StatusSummaryDto } from "../../../client-api";

const Summary = () => {
  const api = useAPI(ReportsApi);

  const state = useAsyncRetry(async () => {
    return api.reportsControllerGetStatus().then(async (data) => {
      const main: any[] = [];
      const secondary: StatusSummaryDto[] = [];
      data.forEach((item) => {
        if (item.tryMatch)
          main.push({ ...item, percent: (item.count / item.total) * 100 });
        else secondary.push(item);
      });
      return {
        main,
        secondary,
      };
    });
  });

  const dataTable =
    state.value?.main.map((i) => ({ category: i.name, value: i.count })) || [];

  const data = {
    name: "status",
    children: [
      { name: "Não reportado", value: 1840 },
      { name: "Completo", value: 3443 },
      { name: "Divergente", value: 125 },
      { name: "Faturas não localizadas", value: 569 },
      { name: "outro stattus", value: 75 },
    ],
  };

  const color = ["#6394f9", "#62daaa", "#65789b"];

  const config = {
    data,
    colorField: "value",
    valueField: "value",
    scale: {
      color: {
        range: [
          "#4e79a7",
          "#f28e2c",
          "#e15759",
          "#76b7b2",
          "#59a14f",
          "#edc949",
          "#af7aa1",
          "#ff9da7",
          "#9c755f",
          "#bab0ab",
        ],
      },
    },
    legend: false,
  };

  return (
    <Card>
      <Treemap {...config} />
      <div
        style={{
          border: "1px solid #e8e8e8",
          borderRadius: "5px",
          padding: "1rem",
        }}
      >
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  padding: "0.5rem",
                  borderBottom: "1px solid #e8e8e8",
                }}
              >
                Cor
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "0.5rem",
                  borderBottom: "1px solid #e8e8e8",
                }}
              >
                Categoria
              </th>
              <th
                style={{
                  textAlign: "right",
                  padding: "0.5rem",
                  borderBottom: "1px solid #e8e8e8",
                }}
              >
                Valor
              </th>
            </tr>
          </thead>
          <tbody>
            {dataTable.map((item, index) => (
              <tr key={item.category}>
                <td style={{ padding: "0.5rem" }}>
                  <div
                    style={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: color[index],
                      borderRadius: "50%",
                    }}
                  ></div>
                </td>
                <td style={{ padding: "0.5rem" }}>{item.category}</td>
                <td style={{ padding: "0.5rem", textAlign: "right" }}>
                  {item.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default Summary;
