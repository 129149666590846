/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateInvoicesNotesDataDto
 */
export interface UpdateInvoicesNotesDataDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataMultiplierIdentifierNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataNfe?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataIdentifierNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataUf?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataCity?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataBillingDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataSeedUse?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataCultivate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataQtyPackaging?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataClientName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataBatch?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataTechnology?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataWeightPackaging?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataWeightKq?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    dataCfop?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoicesNotesDataDto
     */
    editDataCfop?: string;
}

/**
 * Check if a given object implements the UpdateInvoicesNotesDataDto interface.
 */
export function instanceOfUpdateInvoicesNotesDataDto(value: object): value is UpdateInvoicesNotesDataDto {
    return true;
}

export function UpdateInvoicesNotesDataDtoFromJSON(json: any): UpdateInvoicesNotesDataDto {
    return UpdateInvoicesNotesDataDtoFromJSONTyped(json, false);
}

export function UpdateInvoicesNotesDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInvoicesNotesDataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'] == null ? undefined : json['status'],
        'dataMultiplierIdentifierNumber': json['dataMultiplierIdentifierNumber'] == null ? undefined : json['dataMultiplierIdentifierNumber'],
        'dataNfe': json['dataNfe'] == null ? undefined : json['dataNfe'],
        'dataIdentifierNumber': json['dataIdentifierNumber'] == null ? undefined : json['dataIdentifierNumber'],
        'dataUf': json['dataUf'] == null ? undefined : json['dataUf'],
        'dataCity': json['dataCity'] == null ? undefined : json['dataCity'],
        'dataBillingDate': json['dataBillingDate'] == null ? undefined : json['dataBillingDate'],
        'dataSeedUse': json['dataSeedUse'] == null ? undefined : json['dataSeedUse'],
        'dataCultivate': json['dataCultivate'] == null ? undefined : json['dataCultivate'],
        'dataQtyPackaging': json['dataQtyPackaging'] == null ? undefined : json['dataQtyPackaging'],
        'dataClientName': json['dataClientName'] == null ? undefined : json['dataClientName'],
        'dataBatch': json['dataBatch'] == null ? undefined : json['dataBatch'],
        'dataTechnology': json['dataTechnology'] == null ? undefined : json['dataTechnology'],
        'dataWeightPackaging': json['dataWeightPackaging'] == null ? undefined : json['dataWeightPackaging'],
        'dataWeightKq': json['dataWeightKq'] == null ? undefined : json['dataWeightKq'],
        'dataCfop': json['dataCfop'] == null ? undefined : json['dataCfop'],
        'editDataCfop': json['editDataCfop'] == null ? undefined : json['editDataCfop'],
    };
}

export function UpdateInvoicesNotesDataDtoToJSON(value?: UpdateInvoicesNotesDataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'dataMultiplierIdentifierNumber': value['dataMultiplierIdentifierNumber'],
        'dataNfe': value['dataNfe'],
        'dataIdentifierNumber': value['dataIdentifierNumber'],
        'dataUf': value['dataUf'],
        'dataCity': value['dataCity'],
        'dataBillingDate': value['dataBillingDate'],
        'dataSeedUse': value['dataSeedUse'],
        'dataCultivate': value['dataCultivate'],
        'dataQtyPackaging': value['dataQtyPackaging'],
        'dataClientName': value['dataClientName'],
        'dataBatch': value['dataBatch'],
        'dataTechnology': value['dataTechnology'],
        'dataWeightPackaging': value['dataWeightPackaging'],
        'dataWeightKq': value['dataWeightKq'],
        'dataCfop': value['dataCfop'],
        'editDataCfop': value['editDataCfop'],
    };
}

