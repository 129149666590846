import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { BaseLayout, DashboardLayout } from "./layouts";
import MenuData from "./menu";
import LoginPage from "./pages/login";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.locale("pt-br");

const addRoutes = (routes: any[]) =>
  routes.map((route, index) =>
    route.routes ? (
      <Route key={index} path={route.path}>
        {addRoutes(route.routes)}
      </Route>
    ) : (
      <Route key={index} path={route.path} element={<route.component />} />
    )
  );

function App() {
  return (
    <BaseLayout>
      <Router>
        <Routes>
          <Route key={"loginpage"} path={"/login"} element={<LoginPage />} />
          <Route path="/" element={<DashboardLayout />}>
            <Route>{addRoutes(MenuData)}</Route>
          </Route>
        </Routes>
      </Router>
    </BaseLayout>
  );
}

export default App;
