// src/pages/HomePage.js
import React, { createRef, useState } from "react";
import {
  ActionType,
  PageContainer,
  ProColumns,
  ProTable,
} from "@ant-design/pro-components";
import { Badge, Button, Select, Space, Spin, Tag, Typography } from "antd";
import { useAPI } from "../../client-api/utils/api";
import { InvoicesNotesDataApi } from "../../client-api";
import TagInvoice from "../../components/invoices-status/tag-invoice";
import { InvoiceDef } from "../../components/invoices-status/types";
import {
  BranchesOutlined,
  EllipsisOutlined,
  LoadingOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import labels from "./data-fields";
import ClientBillingEdit from "../../components/client-billing-edit/indes";
// import { defaultFilter } from "../../components/utils";
import BgProcessPopover from "../../components/bg-process-popover";
import { cleanObj, textToIdentificationNumber } from "../../components/utils";
import { useAsyncRetry } from "react-use";

const ClientBilling: React.FC = () => {
  const actionRef: any = createRef<ActionType>();
  const api = useAPI(InvoicesNotesDataApi);

  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState<string>("all");
  const [filteredInfo, setFilteredInfo] = useState<{ [key: string]: any }>({});

  const totals = useAsyncRetry(
    async () => await api.invoicesNotesDataControllerGetTotals(filteredInfo),
    [filteredInfo]
  );

  const renderBadge = (text: string, count?: number) => {
    const info = totals.loading ? (
      <Spin indicator={<LoadingOutlined spin />} size="small" />
    ) : (
      count
    );
    const sufix = (
      <Badge
        count={info}
        showZero={true}
        overflowCount={999}
        color="orange"
        style={{
          marginBlockStart: -2,
          marginInlineStart: 4,
        }}
      />
    );

    return (
      <>
        {text}
        {sufix}
      </>
    );
  };

  const renderText = (editData: string, data: string) => {
    if (data !== editData) {
      return (
        <Space direction="vertical" size={0}>
          <Typography.Text>{editData}</Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 10 }}>
            {data}
          </Typography.Text>
        </Space>
      );
    }
    return editData;
  };

  const clientBillingColumns: ProColumns[] = [
    {
      title: "id",
      dataIndex: "id",
      hidden: true,
      hideInSearch: true,
    },
    {
      title: <EllipsisOutlined />,
      fixed: "right",
      key: "options",
      width: 64,
      hideInSearch: true,
      render: (_, record) => (
        <Space.Compact block>
          <ClientBillingEdit
            filters={{ id: record.id }}
            iconMode
            onConfirm={() => actionRef?.current?.reload()}
            size="small"
          />
          <Button
            title="revisões"
            icon={<BranchesOutlined />}
            size="small"
            disabled
          />
        </Space.Compact>
      ),
    },
    {
      title: labels["status"],
      dataIndex: "status",
      width: 100,
      filteredValue: filteredInfo?.status || null,
      filters: Object.keys(InvoiceDef).map((el) => ({
        value: el,
        text: InvoiceDef[el].label,
      })),
      renderText: (value: string, record) => (
        <>
          <TagInvoice value={value} />
          {record.revision && <WarningOutlined />}
        </>
      ),
      renderFormItem: (__, props) => (
        <Select
          {...props}
          mode="multiple"
          allowClear
          placeholder="Por favor insira"
          options={Object.keys(InvoiceDef).map((el) => ({
            value: el,
            label: InvoiceDef[el].label,
          }))}
        />
      ),
      search: {
        transform: (value, namePath) => ({
          [namePath]: value?.length === 0 ? undefined : value,
        }),
      },
    },
    {
      title: labels["editDataMultiplierIdentifierNumber"],
      dataIndex: "editDataMultiplierIdentifierNumber",
      width: 200,
      // ...defaultFilter("editDataMultiplierIdentifierNumber"),
      renderText: (text, record) =>
        renderText(
          textToIdentificationNumber(text),
          textToIdentificationNumber(record.dataMultiplierIdentifierNumber)
        ),
    },
    {
      title: labels["editDataCfop"],
      dataIndex: "editDataCfop",
      width: 80,
      renderText: (text, record) => renderText(text, record.dataCfop),
    },
    {
      title: labels["editDataNfe"],
      dataIndex: "editDataNfe",
      width: 100,
      // ...defaultFilter("editDataNfe"),
      renderText: (text, record) => renderText(text, record.dataNfe),
    },
    {
      title: labels["editDataBatch"],
      dataIndex: "editDataBatch",
      width: 100,
      // ...defaultFilter("editDataBatch"),
      renderText: (text, record) => renderText(text, record.dataBatch),
    },
    {
      title: labels["editDataIdentifierNumber"],
      dataIndex: "editDataIdentifierNumber",
      width: 200,
      // ...defaultFilter("editDataIdentifierNumber"),
      renderText: (text, record) =>
        renderText(
          textToIdentificationNumber(text),
          textToIdentificationNumber(record.dataIdentifierNumber)
        ),
    },
    {
      title: labels["editDataUf"],
      dataIndex: "editDataUf",
      width: 30,
      // ...defaultFilter("editDataUf"),
      renderText: (text, record) => renderText(text, record.dataUf),
    },
    {
      title: labels["editDataCity"],
      dataIndex: "editDataCity",
      width: 150,
      // ...defaultFilter("editDataCity"),
      renderText: (text, record) => renderText(text, record.dataCity),
    },
    {
      title: labels["editDataBillingDate"],
      dataIndex: "editDataBillingDate",
      width: 90,
      // ...defaultFilter("editDataBillingDate"),
      renderText: (text, record) => renderText(text, record.dataBillingDate),
    },
    {
      title: labels["editDataSeedUse"],
      dataIndex: "editDataSeedUse",
      width: 80,
      // ...defaultFilter("editDataSeedUse"),
      renderText: (text, record) => renderText(text, record.dataSeedUse),
    },
    {
      title: labels["editDataCultivate"],
      dataIndex: "editDataCultivate",
      width: 200,
      // ...defaultFilter("editDataCultivate"),
      renderText: (text, record) => renderText(text, record.dataCultivate),
    },
    {
      title: labels["editDataQtyPackaging"],
      dataIndex: "editDataQtyPackaging",
      width: 80,
      // ...defaultFilter("editDataQtyPackaging"),
      renderText: (text, record) => renderText(text, record.dataQtyPackaging),
    },
    {
      title: labels["editDataClientName"],
      dataIndex: "editDataClientName",
      width: 200,
      // ...defaultFilter("editDataClientName"),
      renderText: (text, record) => renderText(text, record.dataClientName),
    },
    {
      title: labels["editDataTechnology"],
      dataIndex: "editDataTechnology",
      width: 80,
      // ...defaultFilter("editDataTechnology"),
      renderText: (text, record) => renderText(text, record.dataTechnology),
    },
    {
      title: labels["editDataWeightPackaging"],
      dataIndex: "editDataWeightPackaging",
      width: 80,
      // ...defaultFilter("editDataWeightPackaging"),
      renderText: (text, record) =>
        renderText(text, record.dataWeightPackaging),
    },
    {
      title: labels["editDataWeightKq"],
      dataIndex: "editDataWeightKq",
      width: 80,
      // ...defaultFilter("editDataWeightKq"),
      renderText: (text, record) => renderText(text, record.dataWeightKq),
    },
  ];

  const renderFilteredInfo = () => {
    const actions = Object.keys(filteredInfo)
      .filter((el) => !!filteredInfo?.[el] && labels[el as keyof typeof labels])
      .map((el: string) => {
        return (
          <Tag
            closable={false}
            onClose={(e) => {
              e.preventDefault();
              // setFilteredInfo({ ...filteredInfo, [el]: null });
              // actionRef?.current?.reload();
            }}
          >
            {labels[el as keyof typeof labels] || ""}
            {": "}
            {filteredInfo[el]?.toString()}
          </Tag>
        );
      });
    if (actions.length > 0) {
      actions.push(
        <ClientBillingEdit
          filters={filteredInfo}
          onConfirm={() => actionRef?.current?.reload()}
        />
      );
    }
    actions.push(
      <BgProcessPopover
        action="export"
        type={["invoicesNotes", "withoutquotasNotes"]}
        doExport={() =>
          activeKey !== "withoutquota"
            ? api.invoicesNotesDataControllerExportAll({
                ...filteredInfo,
              })
            : api.invoicesNotesDataControllerExportAllWithoutQuotas({
                ...filteredInfo,
              })
        }
      />
    );
    return actions;
  };

  return (
    <PageContainer title={false}>
      <ProTable
        // antd site header height
        scroll={{ x: "100vw", y: "100vh" }}
        sticky={{ offsetHeader: 56 }}
        loading={loading}
        columns={clientBillingColumns}
        actionRef={actionRef}
        // params={filteredInfo}
        beforeSearchSubmit={(params) => {
          const p = cleanObj(params);
          setFilteredInfo(p);
          return p;
        }}
        request={(params) => {
          setLoading(true);
          const filterInvoices =
            activeKey === "inrevision"
              ? { ...params, revision: true }
              : { ...params };
          delete filterInvoices.current;
          delete filterInvoices.pageSize;
          const getItems =
            activeKey !== "withoutquota"
              ? api.invoicesNotesDataControllerFindAll({
                  page: params?.current,
                  pageSize: params?.pageSize,
                  ...filterInvoices,
                })
              : api.invoicesNotesDataControllerFindAllWithoutQuotas({
                  page: params?.current,
                  pageSize: params?.pageSize,
                  ...filterInvoices,
                });
          return getItems
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            })
            .finally(() => setLoading(false));
        }}
        toolbar={{
          menu: {
            type: "tab",
            activeKey: activeKey,
            items: [
              {
                key: "all",
                label: <span>{renderBadge("Todos", totals.value?.total)}</span>,
              },
              {
                key: "inrevision",
                label: (
                  <span>
                    {renderBadge("Revisão", totals.value?.totalRevision)}
                  </span>
                ),
              },
              {
                key: "withoutquota",
                label: (
                  <span>
                    {renderBadge(
                      "Sem cadastro de cotas",
                      totals.value?.totalWithoutQuotas
                    )}
                  </span>
                ),
              },
            ],
            onChange: (key) => {
              setActiveKey(key as string);
              actionRef?.current?.reload();
            },
          },
          actions: renderFilteredInfo(),
        }}
        rowKey="id"
        pagination={{ defaultPageSize: 100 }}
        search={{ layout: "vertical" }}
        dateFormatter="string"
      />
    </PageContainer>
  );
};

export default ClientBilling;
