/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BgProcessDto
 */
export interface BgProcessDto {
    /**
     * 
     * @type {number}
     * @memberof BgProcessDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BgProcessDto
     */
    ownerId: number;
    /**
     * 
     * @type {string}
     * @memberof BgProcessDto
     */
    action: string;
    /**
     * 
     * @type {string}
     * @memberof BgProcessDto
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof BgProcessDto
     */
    progress: number;
    /**
     * 
     * @type {object}
     * @memberof BgProcessDto
     */
    data: object;
    /**
     * 
     * @type {string}
     * @memberof BgProcessDto
     */
    status: string;
    /**
     * 
     * @type {Date}
     * @memberof BgProcessDto
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the BgProcessDto interface.
 */
export function instanceOfBgProcessDto(value: object): value is BgProcessDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('ownerId' in value) || value['ownerId'] === undefined) return false;
    if (!('action' in value) || value['action'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('progress' in value) || value['progress'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    return true;
}

export function BgProcessDtoFromJSON(json: any): BgProcessDto {
    return BgProcessDtoFromJSONTyped(json, false);
}

export function BgProcessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BgProcessDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ownerId': json['ownerId'],
        'action': json['action'],
        'type': json['type'],
        'progress': json['progress'],
        'data': json['data'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function BgProcessDtoToJSON(value?: BgProcessDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'ownerId': value['ownerId'],
        'action': value['action'],
        'type': value['type'],
        'progress': value['progress'],
        'data': value['data'],
        'status': value['status'],
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

